import React from 'react'
import './Tab.css'
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import Project from '../projectList/Project';
import Skill from '../skillsList/skill';
function Tabs({ theme }) {
  const containerBg = {
    background: theme === "light" ? 'var(--white)' : 'var(--hoverDark)'
  }
  const buttonColor = {
    color: theme === "light" ? 'var(--boldColor)' : 'var(--darkModeSemiboldColor)',
    background: theme === "light" ? 'var(--white)' : 'var(--hoverDark)'
  }
  return (
    <Router>
      <div className='tabContainer flex' style={containerBg}>
        <NavLink to="/" className='tabBtn flex' id={theme === "light" ? "light" : "dark"} style={buttonColor}>
          Project
        </NavLink>
        <NavLink to="/skills" className='tabBtn flex' id={theme === "light" ? "light" : "dark"} style={buttonColor}>
          Tech
        </NavLink>
      </div>
      <Routes className='tabContainer flex' style={containerBg}>
        <Route path="*" element={<Project />} />
        <Route path="/skills" element={<Skill theme={theme} />} />
      </Routes>
    </Router>
  )
}

export default Tabs
