import React from 'react'
import './Project.css'
import linkSvg from "../../assets/svgs/link.svg";
import cib from "../../assets/images/Project_Images/cib.png";
import lishalPortfolio from "../../assets/images/Project_Images/lishalPortfolio1.png";
import covid from "../../assets/images/Project_Images/covid.png";
import counter from "../../assets/images/Project_Images/reactCounter.png";
import tictactoe from "../../assets/images/Project_Images/tic-tac-toe.png";
import noimage from "../../assets/images/Project_Images/noimage.jpg";
import lianChat from "../../assets/images/Project_Images/lian-chat.png";
import linkCrossSvg from "../../assets/svgs/link-cross.svg";
function Project({ theme }) {
  return (
    <div className='projectContainer grid'>
      <div className="card">
        <div className="image flex">
          <img src={lianChat} alt="LIANImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              LIAN-Chat
            </div>
            <div className="description lightBoldColor">
              <span>Tech: React, Mongo DB, Node JS, Express JS, Socket.Io </span>
            </div>
            <div className="link flex">
              <a href='https://lian-chat.vercel.app/' target='__blank' className='flex'>
                <img src={linkSvg} alt="linkBnt" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={cib} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              CIB
            </div>
            <div className="description lightBoldColor">
              <span>Tech: Angular, Prime NG, Font Awesome </span>
            </div>
            <div className="link flex">
              <a href='https://cib-chi.vercel.app/dashboard/company/companyDetail' target='__blank' className='flex'>
                <img src={linkSvg} alt="linkBnt" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={lishalPortfolio} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              Personal Projcet V.1
            </div>
            <div className="description lightBoldColor">
              <span>Tech: React, Font Awesome</span>
            </div>
            <div className="link flex">
              <a href='https://lishal.vercel.app' target='__blank' className='flex'>
                <img src={linkSvg} alt="linkBnt" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={covid} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              Live Covid Tracker
            </div>
            <div className="description lightBoldColor">
              <span>Tech: HTML, CSS, JS, jQuery, Data Tables, Bootstrap, Font Awesome</span>
            </div>
            <div className="link flex">
              <a href='https://liveupdate-covid19.netlify.app/' target='__blank' className='flex'>
                <img src={linkSvg} alt="linkBnt" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={counter} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              React Counter
            </div>
            <div className="description lightBoldColor">
              <span>Tech: React</span>
            </div>
            <div className="link flex">
              <a href='https://simplecounter-lishal.netlify.app/' target='__blank' className='flex'>
                <img src={linkSvg} alt="linkBnt" className='cross' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={tictactoe} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              Tic-Tac-Toe
            </div>
            <div className="description lightBoldColor">
              <span>Tech: HTML, CSS, JS</span>
            </div>
            <div className="link flex">
              <a href='https://tic-tac-toe-coral-gamma.vercel.app/' target='__blank' className='flex'>
                <img src={linkSvg} alt="linkBnt" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={noimage} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              Cinema Management System
            </div>
            <div className="description lightBoldColor">
              <span>Tech: Laravel, MySQl, Font Awesome</span>
            </div>
            <div className="link flex">
              <div className='flex'>
                <img src={linkCrossSvg} alt="linkcrossBnt" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={noimage} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              Steganography
            </div>
            <div className="description lightBoldColor">
              <span>Tech: Laravel, MySQl, Font Awesome</span>
            </div>
            <div className="link flex">
              <div className='flex'>
                <img src={linkCrossSvg} alt="linkcrossBnt" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="image flex">
          <img src={noimage} alt="CIBImage" />
          <div className="overlay flex">
            <div className="heading lightBoldColor">
              Restaurant Booking System
            </div>
            <div className="description lightBoldColor">
              <span>Tech: Laravel, MySQl, Font Awesome</span>
            </div>
            <div className="link flex">
              <div className='flex'>
                <img src={linkCrossSvg} alt="linkcrossBnt" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project