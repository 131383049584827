import React from 'react'
import './skill.css'
import htmlsvg from '../../assets/skillsSvg/html-5.svg'
import csssvg from '../../assets/skillsSvg/css.svg'
import jssvg from '../../assets/skillsSvg/js.svg'
import reactsvg from '../../assets/skillsSvg/react.svg'
import angularsvg from '../../assets/skillsSvg/angular.svg'
import laravelsvg from '../../assets/skillsSvg/laravel.svg'
import nodesvg from '../../assets/skillsSvg/node-js.svg'
import phpsvg from '../../assets/skillsSvg/php.svg'
import bootstrapsvg from '../../assets/skillsSvg/bootstrap.svg'
import mysqlsvg from '../../assets/skillsSvg/mysql.svg'
import mongosvg from '../../assets/skillsSvg/mongo.svg'
import socketsvg from '../../assets/skillsSvg/socket.svg'
function skill({ theme }) {
  return (
    <div className='skillsList grid flex'>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={htmlsvg} alt="HTMLSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              HTML
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={csssvg} alt="CSSSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              CSS
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={jssvg} alt="JSSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              JavaScript
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={reactsvg} alt="REACTSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              React
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={angularsvg} alt="ANGULARSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              Angular
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={laravelsvg} alt="LARAVELSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              Laravel
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={nodesvg} alt="NODEJSSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              Node JS
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={phpsvg} alt="PHPSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              PHP
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={bootstrapsvg} alt="BOOTSTRAPSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              Bootstrap
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={mysqlsvg} alt="MYSQLSVG" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              MySQL
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={mongosvg} alt="MongoSvg" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              MongoDB
            </span>
          </div>
        </div>
      </div>
      <div className="skillCard">
        <div className="skillSvg">
          <img src={socketsvg} alt="SocketSvg" />
          <div className={`svg-name flex ${theme === "light" ? "lightSemiboldColor" : "darkSemiboldColor"}`}>
            <span>
              Socket.io
            </span>
          </div>
        </div>
      </div>

    </div>
  )
}

export default skill