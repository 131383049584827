import React from 'react'
import  "./Profile.css";
import lishalPic from "../../assets/images/lishal.png";
import darkgithub from "../../assets/svgs/github_dark.svg";
import lightgithub from "../../assets/svgs/github_light.svg";
import lightlinkedin from "../../assets/svgs/linkedin_light.svg";
import darklinkedin from "../../assets/svgs/linkedin_dark.svg";
import darkfacebook from "../../assets/svgs/facebook_dark.svg";
import lightfacebook from "../../assets/svgs/facebook_light.svg";
import lightinstagram from "../../assets/svgs/instagram_light.svg";
import darkinstagram from "../../assets/svgs/instagram_dark.svg";

function Profile({theme}) {
    const profileName= theme === "light" ? "profileName lightBoldColor" :"profileName darkBoldColor"
    const profileDesignation= theme === "light" ? "profileDesignation lightSemiboldColor" :"profileDesignation darkSemiboldColor"
  return (
    <div className="profileSection flex">
            <div className="profilePic flex mainBgColor">
                <img src={lishalPic} alt="Lishal"/>
            </div>
            <div className="profileDetails flex">
                <span className={profileName}>
                    Lishal Bhari
                </span>
                <span className={profileDesignation}>
                    Software Engineer
                </span>
                <div className="profileLinks flex">
                    <a href="https://github.com/lishal" target="_blank" rel="noopener noreferrer">
                        <img src={theme==="light"?lightgithub:darkgithub} alt="Github" />
                    </a>   
                    <a href="https://www.linkedin.com/in/lishal-bhari-706991173/" target="_blank" rel="noopener noreferrer">
                        <img src={theme==="light"?lightlinkedin:darklinkedin} alt="linkedin" />
                    </a>   
                    <a href="https://www.facebook.com/lishalbhari/" target="_blank" rel="noopener noreferrer">
                        <img src={theme==="light"?lightfacebook:darkfacebook} alt="facebook" />
                    </a>   
                    <a href="https://www.instagram.com/all_li/" target="_blank" rel="noopener noreferrer">
                        <img src={theme==="light"?lightinstagram:darkinstagram} alt="instagram" />
                    </a>   
                </div>
            </div>
        </div>
  )
}

export default Profile