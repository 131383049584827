import "./App.css";
import { useState } from "react";
import Mode from "./components/modeControl/Mode";
import Profile from "./components/profileSection/Profile";
import Profilefacts from "./components/profileFacts/Profilefacts";
import Buttons from "./components/profileButton/Buttons";
import Tabs from "./components/tabButtons/Tabs";
import Footer from "./components/footerSection/Footer";

function App() {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  const themeStyle = {
    background: `${
      theme === "light" ? "var(--lightColor)" : "var(--darkColor)"
    }`,
  };
  return (
    <div className="container flex" style={themeStyle}>
      <Mode toggleTheme={toggleTheme} theme={theme} />
      <Profile theme={theme} />
      <Profilefacts theme={theme} />
      <Buttons theme={theme} />
      <Tabs theme={theme} />
      <Footer theme={theme} />
    </div>
  );
}

export default App;
