import React from "react";
import "./Profilefacts.css";

function Profilefacts({ theme }) {
  return (
    <div className="flex">
      <div className="description flex">
        <div
          className={
            theme === "light" ? "lightBoldColor number" : "darkBoldColor number"
          }
        >
          2+
        </div>
        <div
          className={
            theme === "light"
              ? "lightSemiboldColor detail"
              : "darkSemiboldColor detail"
          }
        >
          Years of work experience
        </div>
      </div>
      <div className="description flex">
        <div
          className={
            theme === "light" ? "lightBoldColor number" : "darkBoldColor number"
          }
        >
          10+
        </div>
        <div
          className={
            theme === "light"
              ? "lightSemiboldColor detail"
              : "darkSemiboldColor detail"
          }
        >
          Completed projects
        </div>
      </div>
      <div className="description flex">
        <div
          className={
            theme === "light" ? "lightBoldColor number" : "darkBoldColor number"
          }
        >
          4
        </div>
        <div
          className={
            theme === "light"
              ? "lightSemiboldColor detail"
              : "darkSemiboldColor detail"
          }
        >
          Satisfied customers
        </div>
      </div>
    </div>
  );
}

export default Profilefacts;
