import React from "react";
import "./Mode.css";
import sun from "../../assets/svgs/sun.svg";
import moon from "../../assets/svgs/moon.svg";

function Mode({ toggleTheme, theme }) {
  const backgroundSvg = {
    background: `url(${theme === "light" ? moon : sun})`,
  };
  const outerCircleStyle = {
    background: `${theme === "light" ? "var(--white)" : "var(--hoverDark)"}`,
  };
  return (
    <div className="mode_control flex">
      <div
        className="outerCirle flex"
        onClick={toggleTheme}
        style={outerCircleStyle}
        id="modeControl"
      >
        <div className="modeButton" style={backgroundSvg}></div>
      </div>
    </div>
  );
}

export default Mode;
